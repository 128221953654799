<template>
	<div>
		<the-navigation v-if="auth"></the-navigation>
		<router-view class="top-offset"></router-view>
		<alerts></alerts>
	</div>
</template>

<script>
import TheNavigation from './comp/theNavigation';
import alerts from './comp/alerts';
export default {
	components: {
		'the-navigation': TheNavigation,
		'alerts': alerts,
	},
	computed: {
		auth() {
			if (this.$store.state.token) {
				return true;
			}
			return false;
		}
	}
}
</script>

<style scoped>
	.top-offset {
		padding-top: 73px;
	}
</style>