<template>
	<div class="card border-top-0 overflow-visible">
		<div class="card-header m-0 p-1" :class="chapterColor">

			<div v-if=" ! editMode" class="d-flex w-100">
				<button type="button" class="d-flex align-items-center btn-custom py-2" @click="showPagesToggle()">
					<i class="md pr-1">{{showPages ? 'expand_more' : 'chevron_right' }}</i>
					<strong class="break-word mx-auto">{{ chapter.name }}</strong>
					<div class="badge badge-light badge-width no-select">{{ chapter.status }}</div>
				</button>

				<button type="button" class="btn btn-sm border-0 text-body" @click="setEditMode()"><i class="md md-fix">edit</i></button>
			</div>
			<div v-else class="d-flex w-100">
				<div class="align-self-center px-1"><i class="md md-fix">drag_indicator</i></div>
				<input type="text" v-model="editedChapterName" class="form-control">
				<button type="button" class="btn btn-sm border-0 text-body py-0" @click="addPage()"><i class="md md-fix">add</i>Page</button>
				<button type="button" class="btn btn-sm border-0 text-body py-0" @click="deleteChapter()"><i class="md md-fix">delete_outline</i>Delete</button>
				<button type="button" class="btn btn-sm border-0 text-body py-0" @click="saveEdit()"><i class="md md-fix">check</i></button>
				<button type="button" class="btn btn-sm border-0 text-body py-0" @click="editMode = false"><i class="md md-fix">close</i></button>
			</div>

			<add-page v-if="addModalVisible" :chapterId="chapter.id" @hide-add-modal="calculateNewHeight">{{chapter.name}}</add-page>

		</div>
		<transition name="show-pages" mode="out-in" @enter="enter" @leave="leave">
			<div class="collapse show" v-show="showPages" ref="listContainer">
				<ul class="list-group list-group-flush">
					<page v-for="page in chapter.pages" :page="page" @refresh-height="calculateNewHeight" :key="page.id"></page>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Page from '../comp/page';
import addPage from '../comp/addPage';

export default {
	components: {
		Page,
		addPage,
	},
	props: ['chapter', 'accordion-status'],
	data() {
		return {
			editedChapterName: null,
			editMode: false,
			showPages: false,
			accordionStatusLocal: null,
			addModalVisible: false,
		}
	},
	methods: {
		calculateNewHeight(type) {
			this.addModalVisible = false;
			const listContainer = this.$refs.listContainer;
			if(type == 'addPage') {
				listContainer.style.height = listContainer.scrollHeight + 48 + 'px';
			} else if (type == 'deletePage') {
				listContainer.style.height = listContainer.scrollHeight - 48 + 'px';
			}
		},
		enter(el, done) {
			el.style.height = el.scrollHeight + 'px';
		},
		leave(el, done) {
			el.style.height = 0;
		},
		setEditMode() {
			this.editMode = true;
			this.editedChapterName = this.chapter.name;
		},
		saveEdit() {
			this.$store.dispatch('editChapter', {newName: this.editedChapterName, chapterId: this.chapter.id})
			this.editMode = false;
		},
		deleteChapter() {
			this.$store.dispatch('deleteChapter', this.chapter);
		},
		addPage() {
			this.showPages = true;
			this.editMode = false;
			this.addModalVisible = true;
		},
		showPagesToggle(state) {
			if (state !== undefined) {
				this.showPages = state;
			} else {
				this.showPages = ! this.showPages;
			}
		},
	},
	computed: {
		chapterColor() {
			let color = '';
			switch (this.chapter.status) {
				case 'done':
					color = 'bg-success';
					break;
				case 'empty':
					// color = 'bg-warning';
					break;
			}
			return color;
		},
	}
}
</script>

<style scoped>
	input.form-control {
		height: 37px;
	}
	.overflow-visible {
		overflow: visible;
	}

	.btn-custom {
		background-color: transparent;
		border: none;
		width: 100%;
	}
	.btn-custom:focus, .btn:focus {
		outline: thin dotted;
		box-shadow: none;
	}

	.no-outline {
		outline: none;
	}

	.badge-width {
		width: 5rem;
		margin-left: 0;
	}

	.break-word {
		overflow-wrap: anywhere;
	}

	.show-pages-enter-active {
		transition: height .1s ease-in;
		overflow: hidden;
	}

	.show-pages-leave-active {
		transition: height .1s ease-out;
		overflow: hidden;
	}

	.show-pages-enter {
		height: 0;
	}
</style>