'use strict'
import "bootswatch/dist/lumen/bootstrap.min.css";
import Vue from 'vue';
import router from './router.js';
import store from './store';
import App from './App.vue';

Vue.config.productionTip = false;

if (localStorage.getItem('token') && localStorage.getItem('user')) {
	store.commit('setUser', {'user': JSON.parse(localStorage.getItem('user')), 'token': localStorage.getItem('token')})
} else {
	router.push({name: 'login'});
}

new Vue({
	store,
	router,
	el: '#app',
	components: {
		'app': App,
	},
	render: h => h('app'),
});