<template>
	<div class="container">
		<button @click="init">test</button>
		<div id="graph"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import * as d3 from 'd3';

export default {
	mounted() {
		if ( ! this.documents.length) {
			this.$store.dispatch('getDocuments')
			.then(() => {
				this.$store.dispatch('getDocument', this.documents[0].id)
				.then(() => {
					this.init();
				});
			});
		}
	},
	data() {
		return {
			pages: [],
			width: window.innerWidth,
			height: 400,
			margin: {
				left: 50,
				right: 50,
				top: 100,
				bottom: 50,
			},
		}
	},
	methods: {
		init() {
			this.pages = this.chapters.map(chapter => {
				if (chapter.pages) {
					return [...chapter.pages];
				}
			}).flat();
			console.log(this.pages);
			const svg = d3
			.select('#graph')
			.append('svg')
			.attr('width', this.width)
			.attr('height', this.height)
			.append('g')
			.style('transform', `translate(${this.margin.left}px, ${this.margin.top}px`);

			const yAccessor = d => parseInt(d.page_length);
			// const timeFormatter = d3.timeFormat("%m")
			const xAccessor = d => new Date(d.read_at);
			// const xAccessor = d => timeFormatter(new Date(d.read_at));
			// const xAccessor = d => d3.timeFormat("%Y-%m-%d").call(this, new Date(d.read_at));

			// console.log(xAccessor(this.pages[3]));

			const yScale = d3
				.scaleLinear()
				.domain([0, Math.max(...this.pages.map(elem => elem.page_words))])
				.range([this.height, 0]);
			const xScale = d3
				.scaleTime()
				.domain(d3.extent(this.pages, xAccessor))
				.range([0, this.width]);

		const line = d3
				.line()
				.x(d => xScale(xAccessor(d)))
				.y(d => yScale(yAccessor(d)))
console.log(line(this.pages));
			svg.append('path')
				.attr('d', line(this.pages))
				.attr('fill', 'none')
				.attr('stroke', 'rgb(34 150 243)')
				.attr('stroke-width', 3);

			const yAxisGenerator = d3
				.axisLeft()
				.scale(yScale)
				.ticks(5)
				.tickValues(d3.range(25, 125, 25));
			svg.append('g').call(yAxisGenerator);

			const xAxisGenerator = d3.axisBottom().scale(xScale).ticks(5);
			svg.append('g').call(xAxisGenerator).style('transform', `translateY(${this.height}px)`)
		},
	},
	computed: {
		...mapState([
			'documents',
			'document',
			'chapters',
		]),
	}
}
</script>