<template>
	<div>
		<div class="container">
			<h2 class="mt-3 text-center">
				Users
			</h2>
			<table class="table table-bordered">
				<thead class="thead-light">
					<tr>
						<th scope="col">#</th>
						<th scope="col">Name</th>
						<th scope="col">Email</th>
						<th scope="col">Created</th>
						<th scope="col">Last Login</th>
						<th scope="col">Admin</th>
						<!-- <th scope="col"></th> -->
					</tr>
				</thead>
				<tbody>
					<tr v-for="user in users" :key="user.id">
						<th scope="row">{{ user.id }}</th>
						<td>{{ user.name }}</td>
						<td>{{ user.email }}</td>
						<td>{{ parseISO(user.created_at) }}</td>
						<td>{{ parseISO(user.updated_at) }}</td>
						<td>{{ user.admin_level }}</td>
						<td class="p-0 align-middle">
							<div class="btn-group d-flex mx-auto w-max">
								<button type="button" class="btn btn-sm btn-danger" title="delete" :disabled="user.admin_level > 0"><i class="md">delete</i></button>
								<button type="button" class="btn btn-sm btn-primary" title="delete" :disabled="user.admin_level > 0"><i class="md">edit</i></button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="d-flex justify-content-center">
				<button class="btn btn-primary">Add User</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	mounted() {
		this.$store.dispatch('getUsers');
	},
	methods: {
		parseISO(isoString) {
			let [date, time] = isoString.split('T');
			time = time.split('.')[0];
			return `${date} ${time}`;
		},
	},
	computed: {
		...mapState(['users'])
	}
}

</script>

<style scoped>
	.md {
		transform: translateY(4px);
		font-size:1rem;
		/* font-weight:bold; */
	}

	.w-max {
		width: max-content;
	}
</style>