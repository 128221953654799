import Vue from 'vue';
import Router from 'vue-router';
import store from './Store';

import Login from './pages/login.vue';
import Chapters from './pages/chapters.vue';
import Admin from './pages/admin.vue';
import Statistics from './pages/statistics.vue';
import Play from './pages/play.vue';
import Life from './pages/life.vue';

Vue.use(Router);

const routes = [
	{
		path: '/login',
		name: 'login',
		component: Login,
		props: true,
	},
	{
		path: '/',
		name: 'chapters',
		component: Chapters,
		props: true,
		meta: { requireAuth: true }
	},
	{
		path: '/',
		name: 'profile',
		component: null,
		props: true,
		meta: { requireAuth: true }
	},
	{
		path: '/admin',
		name: 'admin',
		component: Admin,
		props: true,
		meta: { requireAuth: true }
	},
	{
		path: '/statistics',
		name: 'statistics',
		component: Statistics,
		props: true,
		meta: { requireAuth: true }
	},
	{
		path: '/play',
		name: 'play',
		component: Play,
		props: true,
		meta: { requireAuth: true }
	},
    {
		path: '/life',
		name: 'life',
		component: Life,
		props: true,
		meta: { requireAuth: true }
	},
];

const router = new Router({
	linkExactActiveClass: 'active',
	mode: 'history',
	base: '/',
	routes,
});

router.beforeEach((to, from ,next) => {
	const token = store.state.token;

	if (to.name == 'login' && token) {
		next({name: 'chapters'});
	}

	if (to.matched.some(record => record.meta.requireAuth)) {
		if (token) {
			if (to.name === 'login') {
				next({
					name: 'chapters',
				});
			} else {
				next();
			}
		} else {
			next({
				name: 'login',
			});
		}
	} else {
		next();
	}
});

export default router;
