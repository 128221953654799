<template>
	<div>
		<div class="d-flex mb-2 ml-auto mr-auto position-fixed progress-bar-holder">
			<div class="custom-progress-bar d-flex justify-content-end bg-success" :style="[$router.currentRoute.name == 'chapters' ? {'width': animatedPercent + '%'} : {}]"></div>
		</div>

		<div class="navbar text-light bg-dark position-fixed">
			<div class="d-flex w-100 justify-content-between">
				<div class="dropdown">
					<div v-if="dropdown" @click="dropdown = false" class="overlay"></div>
					<button type="button" class="align-self-center btn btn-light h-100" @click="dropdown = !dropdown"><i class="md md-fix">menu</i></button>
					<span class="align-self-center text-capitalize ml-3">{{ user.name }}</span>
					<div class="dropdown-menu" :class="[dropdown ? 'show' : '']">
						<router-link :to="{name: 'chapters'}" class="dropdown-item" @click.native="dropdown = false">Chapters</router-link>
						<router-link :to="{name: 'admin'}" class="dropdown-item" v-if="user.admin_level === 1" @click.native="dropdown = false">Admin</router-link>
						<router-link :to="{name: 'statistics'}" class="dropdown-item" @click.native="dropdown = false">Statistics</router-link>
						<router-link :to="{name: 'play'}" class="dropdown-item" v-if="user.admin_level === 1" @click.native="dropdown = false">Playground</router-link>
						<router-link :to="{name: 'life'}" class="dropdown-item" v-if="user.admin_level === 1" @click.native="dropdown = false">Life</router-link>
					</div>
				</div>
				<div v-if="$router.currentRoute.name == 'chapters'" class="percent text-bold align-self-center" :title="'total: ' + totalWords">{{ animatedPercent.toFixed(0) }}%</div>
				<button type="menu" class="btn btn-secondary" @click="logOut">Log Out <i class="md md-fix">logout</i></button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	data() {
		return {
			dropdown: false,
			percent: 0,
			tweenPercent: 0,
		}
	},
	methods: {
		logOut: function () {
			this.$store.commit('unsetUser');
		},
	},
	watch: {
		chapters: function () {
			if (isNaN(this.readWords / this.totalWords)) {
				this.percent = 0;
			} else {
				this.percent = parseInt((this.readWords / this.totalWords) * 100);
			}
		},
		percent: function(newValue, oldValue) {
			// gsap.to(this.$data, { duration: 1, tweenPercent: newValue, ease: 'bounce' });
			let start;
			let speed = 0.01;
			if (oldValue == 0 || Math.abs(newValue - oldValue) > 30) speed = 0.1;

			const vm = this;
			const forward = newValue > oldValue;

			function step(timestamp) {
				if (start === undefined) start = timestamp;
				const elapsed = timestamp - start;

				if (newValue > oldValue) {
					vm.tweenPercent = Math.min(oldValue + (speed * elapsed), newValue);
				} else {
					vm.tweenPercent = Math.max(oldValue - (speed * elapsed), newValue);
				}

				if ((forward && vm.tweenPercent < newValue) || ( ! forward && vm.tweenPercent > newValue)) {
					window.requestAnimationFrame(step);
				}
			}
			window.requestAnimationFrame(step);
		}
	},
	computed: {
		...mapState([
			'chapters',
		]),
		user() {
			if (this.$store.state.user) {
				return this.$store.state.user;
			}
			return {name: 'Guest'};
		},
		pages() {
			return this.chapters.flatMap(e => e.pages);
		},
		totalWords() {
			return [...this.pages].reduce((acc, val) => acc + val.page_words, 0);
		},
		readWords() {
			return [...this.pages].reduce((acc, val) => {
				if (val.read) {
					return acc + val.page_words;
				}
				return acc;
			}, 0);
		},
		animatedPercent() {
			return this.tweenPercent;
		}
	}
}
</script>

<style scoped>

	.navbar {
		top: 1rem;
		z-index: 100;
		width: 100%;
	}

	.progress-bar-holder {
		background-color: var(--gray-dark);
		top: 0;
		z-index: 1001;
		width: 100%;
	}

	.percent {
		font-size: 1.4rem;
	}

	.custom-progress-bar {
		border-radius: 0.15rem;
		height: 1rem;
	}

	.progress-bar-placeholder {
		height: 2rem;
	}

	.name {
		text-transform: capitalize;
		font-size: 1.5rem;
	}
</style>
