!<template>
	<div class="container">
		<h1 class="mt-3 text-center">Playground</h1>
	</div>
</template>

<script>
export default {
    data () {
        return {

        }
    },
	mounted () {

    }
}

// if ('serviceWorker' in navigator) {
// 	window.addEventListener('load', function() {
// // console.log('cucu');
// 		navigator.serviceWorker.register('../sw.js')
// 		.then(reg => {
// 			console.log('SW registration success!', reg);
// 		})
// 		.catch(err => {
// 			console.log('SW registration failed! ', err);
// 		});

// 		Notification.requestPermission()
// 		.then(permission => {
// 			if (permission == 'granted') {
// 				navigator.serviceWorker.ready.then(reg => {
// 					reg.showNotification('Some Title', {
// 						"body": 'Body of text',
// 						"vibrate": [200, 100, 200, 100, 200, 100, 400],
// 						"tag": "request",
// 						"actions": [
// 							{ "action": "yes", "title": "Yes", "icon": "images/yes.png" },
// 							{ "action": "no", "title": "No", "icon": "images/no.png" }
// 						],
// 					});
// 					console.log('SW scope: ', reg.scope);
// 				});
// 			}
// 		});
// 	});
// }
</script>
