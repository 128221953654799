<template>
	<div>
		<div class="container">
			<div class="input-group my-3">
				<select v-model="document" class="form-control">
					<option disabled>Chose document</option>
					<option v-for="doc in documents" :value="doc.id" :key="doc.id">{{doc.name}}</option>
				</select>
				<div class="input-group-append">
					<button class="btn btn-danger border-bottom-0" @click="deleteDocument"><i class="md md-fix"><span class="md md-fix">delete_outline</span></i></button>
					<button class="btn btn-primary border-bottom-0" @click="addDocumentModalVisible = true"><i class="md md-fix">add</i></button>
				</div>
				<add-document v-if="addDocumentModalVisible" @hide-add-modal="addDocumentModalVisible = false"></add-document>
			</div>

			<div v-if="chapters.length" class="btn-group mb-2 d-flex">
				<button type="button" class="btn btn-sm btn-secondary" @click="allDetailsStatus(false)">Close all</button>
				<button type="button" class="btn btn-sm btn-secondary" @click="allDetailsStatus(true)">Open all</button>
			</div>
			<div v-else class="text-center">No chapters</div>

			<div class="accordion">
				<chapter v-for="chapter in chapters" :chapter="chapter" :accordion-status="accordionStatus" ref="chapterToggle" :key="chapter.id"></chapter>
			</div>

			<add-chapter v-if="addChapterModalVisible" @hide-add-modal="addChapterModalVisible = false"></add-chapter>

			<div class="d-flex justify-content-center my-3">
				<button type="button" class="btn btn-primary" @click="addChapterModalVisible = true">Add Chapter</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import Chapter from '../comp/chapter';
import addDocument from '../comp/addDocument';
import addChapter from '../comp/addChapter';

export default {
	components: {
		Chapter,
		addDocument,
		addChapter,
	},
	data() {
		return {
			accordionStatus: false,
			addDocumentModalVisible: false,
			addChapterModalVisible: false,
		}
	},
	props: ['user'],
	mounted() {
		const storedDocumentId = localStorage.getItem('document');
		let documentId = 1;
		if (storedDocumentId && Number.isInteger(parseInt(storedDocumentId))) {
			documentId = localStorage.getItem('document');
		} else if (storedDocumentId) {
			localStorage.removeItem('document');
		}

		this.$store.dispatch('getDocument', documentId);
		this.$store.dispatch('getDocuments');
	},
	methods: {
		allDetailsStatus(status) {
			this.$refs.chapterToggle.forEach(elem => {
				elem.showPagesToggle(status);
			});
		},
		deleteDocument() {
			let accept = confirm(`Do you want to delete this document?`); // TODO: mention document by name
			if (accept) {
				this.$store.dispatch('deleteDocument', this.document);
			}
		}
	},
	computed: {
		...mapState(['chapters', 'documents']),
		document: {
			get() {
				return this.$store.state.document;
			},
			set(val) {
				this.$store.dispatch('getDocument', val);
			}
		}
	}
}
</script>