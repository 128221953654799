import Vue from 'vue';
import Vuex from 'vuex';
import router from './router';
import axios from 'axios';

Vue.use(Vuex);
const store = new Vuex.Store({
	// strict:true,
	state: {
		user: null,
		token: null,
		chapters: [],
		users: [],
		alertCount: 0,
		alerts: [],
		documents: [],
		document: null,
	},
	mutations: {
		setUser(state, payload) {

			/* if(payload.user.email && payload.token) {
				const data = new FormData();
				data.append('email', payload.user.email);
				data.append('token', payload.token);
				axios.post('/api/refresh', data)
				.then(res => {
					if (res.data.status === 'success') {
						state.user = res.data.user;
						state.token = res.data.token;
					} else {
						store.commit('unsetUser');
					}
				})
				.catch(err => {
					console.log('Error refreshing token');
					store.commit('unsetUser');
					return;
				});
			} */

			state.user = payload.user;
			state.token = payload.token;

			if (state.user && state.token) {
				localStorage.setItem('token', state.token);
				localStorage.setItem('user', JSON.stringify(state.user));
				axios.defaults.headers.common['Authorization'] = 'bearer ' + state.token;
			} else {
				console.warn('unset from state check');
				store.commit('unsetUser');
			}
		},
		unsetUser(state) {
			state.user = null;
			state.token = null;
			localStorage.removeItem('token');
			localStorage.removeItem('user');
			router.push({name: 'login'});
		},
		mutateDocument(state, payload) {
			localStorage.setItem('document', payload.id);
			state.chapters = payload.chapters.map(elem => {
				return highlightChapters(elem);
			});
			state.document = payload.id;
		},
		mutateDocuments(state, payload) {
			state.documents = payload;
		},
		mutateCheck(state, payload) {
			state.chapters = state.chapters.map(elem => {
				elem.pages = elem.pages.map(e => {
					if (e.id == payload.id) {
						e.read = payload.state;
					}
					return e;
				});
				return highlightChapters(elem);
			});
		},
		mutateUsers(state, payload) {
			state.users = payload;
		},
		addChapter(state, payload) {
			payload.pages = [];
			payload.state = 'not started';
			state.chapters.push(payload);
			state.chapters = state.chapters.map(elem => {
				return highlightChapters(elem);
			});
		},
		editChapter(state, payload) {
			state.chapters = state.chapters.map(elem => {
				if (elem.id == payload.id) {
					elem.name = payload.name;
				}
				return elem;
			});
		},
		deleteChapter(state, payload) {
			state.chapters = state.chapters.filter(elem => {
				return elem.id != payload;
			});
		},
		addPage(state, payload) {
			payload.page.page_length = 0;
			payload.page.page_words = 0;
			state.chapters = state.chapters.map(elem => {
				if (elem.id == payload.chapterId) {
					elem.pages.push(payload.page);
				}
				return highlightChapters(elem);
			});
		},
		editPage(state, payload) {
			state.chapters = state.chapters.map(elem => {
				if (elem.id == payload.chapterId) {
					elem.pages = elem.pages.map(el => {
						if (el.id == payload.page.id) {
							el = payload.page;
						}
						return el;
					})
				}
				return highlightChapters(elem);
			});
		},
		deletePage(state, payload) {
			state.chapters = state.chapters.map(elem => {
				if (elem.id == payload.chapter_id) {
					elem.pages = elem.pages.filter(elem => {
						return elem.id != payload.id;
					});
				}
				return highlightChapters(elem);
			});
		},
		addAlert(state, {message, type}) {
			let id = ++state.alertCount;
			state.alerts.push({message, type, id});

			setTimeout(() => {
				store.commit('removeAlert', {id});
			}, 3000);
		},
		removeAlert(state, {id }) {
			state.alerts = state.alerts.filter(elem => elem.id !== id);
		},
		addDocument(state, payload) {
			payload.chapters = [];
			state.documents.push(payload);
			store.commit('mutateDocument', payload);
		},
		deleteDocument(state, payload) {
			state.documents = state.documents.filter(elem => elem.id !== payload);
			store.dispatch('getDocument', state.documents[0].id);
		},
	},
	actions: {
		getDocument(ctx, id) {
			return axios.get(`/api/documents/${id}`)
			.then(res => {
				if (Array.isArray(res.data.chapters)) {
					ctx.commit('mutateDocument', res.data);
				}
			})
			.catch((err) => {
				store.commit('unsetUser');
			});
		},
		getDocuments(ctx) {
			return axios.get('/api/documents')
			.then(res => {
				store.commit('mutateDocuments', res.data);
			})
			.catch(err => {
				console.error('Error getting documents');
			});
		},
		toggleCheck(ctx, id) {
			axios.patch('/api/togglePage', {id})
			.then(res => {
				ctx.commit('mutateCheck', {state: res.data.state, id});
			})
			.catch(err => {
				console.error(err);
			});
		},
		getUsers(ctx) {
			axios.get('/api/users')
			.then(res => {
				ctx.commit('mutateUsers', res.data);
			})
			.catch(err => {
				console.error(err);
			});
		},
		addChapter(ctx, chapterName) {
			axios.post('/api/chapters', {document: ctx.state.document, chapterName})
			.then(res => {
				ctx.commit('addChapter', res.data);
				ctx.commit('addAlert', {message: `Chapter <strong>${chapterName}</strong> created`, type: 'success'});
			})
			.catch(err => {
				console.error('Error creating chapter');
				ctx.commit('addAlert', {message: `Unable to create <strong>${chapterName}</strong> chapter`, type: 'danger'});
			});
		},
		editChapter(ctx, editData) {
			axios.patch(`/api/chapters/${editData.chapterId}`, {document: ctx.state.document, newChapterName: editData.newName})
			.then(res => {
				ctx.commit('editChapter', res.data);
				ctx.commit('addAlert', {message: `Updated chapter <strong>${editData.newName}</strong>`, type: 'success'});
			})
			.catch(err => {
				console.error('Error saving chapter change');
				ctx.commit('addAlert', {message: `Unable to update chapter <strong>${editData.newName}</strong>`, type: 'danger'});
			});
		},
		deleteChapter(ctx, chapter) {
			axios.delete(`/api/chapters/${chapter.id}`)
			.then(res => {
				ctx.commit('deleteChapter', res.data.id);
				ctx.commit('addAlert', {message: `Chapter <strong>${chapter.name}</strong> deleted`, type: 'warning'});
			})
			.catch(err => {
				console.error('Error deleting chapter');
				ctx.commit('addAlert', {message: `Unable to delete chapter <strong>${chapter.name}</strong>`, type: 'danger'});
			})
		},
		addPage(ctx, data) {
			axios.post('/api/pages',
				{
					name: data.page.name.value,
					url: data.page.url.value,
					selector: data.page.selector.value,
					chapterId: data.chapterId
				}
			)
			.then(res => {
				ctx.commit('addPage', {page: res.data, chapterId: data.chapterId});
				ctx.commit('addAlert', {message: `Page <strong>${data.page.name.value}</strong> created`, type: 'success'});
			})
			.catch(err => {
				console.error('Error creating page');
				ctx.commit('addAlert', {message: `Unable to create page <strong>${data.page.name.value}</strong>`, type: 'danger'});
			});
		},
		editPage(ctx, data) {
			axios.patch(`/api/pages/${data.pageId}`,
				{
					pageId: data.pageId,
					name: data.page.name.value,
					url: data.page.url.value,
					selector: data.page.selector.value,
					chapterId: data.chapterId
				}
			)
			.then(res => {
				ctx.commit('editPage', {page: res.data, chapterId: data.chapterId});
				store.commit('addAlert', {message: `Updated page <strong>${data.page.name.value}</strong>`, type: 'success'});
			})
			.catch(err => {
				console.error('Error updating page');
				ctx.commit('addAlert', {message: `Unable to update <strong>${data.page.name.value}</strong>`, type: 'danger'});
			});
		},
		deletePage(ctx, data) {
			axios.delete(`/api/pages/${data.page.id}`)
			.then(res => {
				ctx.commit('deletePage', res.data);
				ctx.commit('addAlert', {message: `Deleted page <strong>${data.page.name}</strong>`, type: 'warning'});
			})
			.catch(err => {
				console.error('Error deleting page');
				ctx.commit('addAlert', {message: `Unable to delete page <strong>${data.page.name}</strong>`, type: 'danger'});
			});
		},
		refreshPage(ctx, data) {
			return axios.get(`/api/pages/${data}`)
			.then(res => {
				ctx.commit('editPage', {page: res.data, chapterId: res.data.chapter_id});
				ctx.commit('addAlert', {message: `Word count refreshed to: <strong>${res.data.page_words}</strong>`, type: 'success'});
			})
			.catch(err => {
				console.error('Error refreshing word count');
				ctx.commit('addAlert', {message: `Unable to refresh word count`, type: 'danger'});
			});
		},
		addDocument(ctx, data) {
			axios.post(`/api/documents`,
				{
					name: data.name.value,
					url: data.url.value,
				}
			)
			.then(res => {
				ctx.commit('addDocument', res.data);
				ctx.commit('addAlert', {message: `Document ${res.data.name} created`, type: 'success'});
			})
			.catch(err => {
				console.error('Error creating document');
				ctx.commit('addAlert', {message: `Unable to add document`, type: 'danger'});
			})
		},
		deleteDocument(ctx, data) {
			axios.delete(`/api/documents/${data}`)
			.then(res => {
				ctx.commit('deleteDocument', data); // TODO: delete based on response
				// ctx.commit('addAlert', {message: `Document ${data.name} deleted`, type: 'warning'});
				ctx.commit('addAlert', {message: `Document deleted`, type: 'warning'}); // TODO: mention document by name
			})
			.catch(err => {
				console.error('Error deleting document');
				ctx.commit('addAlert', {message: `Unable to delete document`, type: 'danger'});
			})
		},
	}
});

function highlightChapters(chapter) {
	const readPages = chapter.pages.filter((val) => val.read);
	if (chapter.pages.length == 0) {
		chapter.status = 'empty';
	} else if (readPages.length == chapter.pages.length) {
		chapter.status = 'done';
	} else if (readPages.length > 0) {
		chapter.status = 'started';
	} else {
		chapter.status = 'not started';
	}
	return chapter;
}

export default store;
