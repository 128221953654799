<template>
	<div>
		<div class="modal-backdrop fade show"></div>
		<div class="fade modal show d-block" @click.self="$emit('hide-add-modal')" @keyup.esc="$emit('hide-add-modal')">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Add new chapter
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('hide-add-modal')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<label for="chapterName">Name</label>
						<input type="text"
						id="chapterName"
						ref="firstInput"
						v-model="chapterName"
						class="form-control"
						:class="[chapterNameErr ? 'is-invalid' : '']"
						@input="chapterName.length ? chapterNameErr = false : null">
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="$emit('hide-add-modal')">Cancel</button>
						<button type="button" class="btn btn-primary" @click="submitChapter">Add</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		this.$refs.firstInput.focus();
	},
	data() {
		return {
			chapterName: '',
			chapterNameErr: false,
		}
	},
	methods: {
		submitChapter() {
			if (this.chapterName.length > 0) {
				this.$store.dispatch('addChapter', this.chapterName);
				this.$emit('hide-add-modal');
			} else {
				this.chapterNameErr = true;
			}
		}
	},
}
</script>

<style scoped>
	.overlay.opaque {
		background-color: rgba(0, 0, 0, 0.5);
	}
</style>