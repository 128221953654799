!<template>
	<div class="container">
		<h1 class="mt-3 text-center">Life</h1>

        <div class="input-group my-3">
            <!-- <label for="birthDate">Birth date</label> -->
            <input type="text"
                ref="firstInput"
                v-model="startYear"
                class="form-control"
            >
            <div class="input-group-append">
                <!-- <button class="btn btn-danger border-bottom-0" @click="deleteDocument"><i class="md md-fix"><span class="md md-fix">delete_outline</span></i></button> -->
                <button class="btn btn-primary border-bottom-0" @click="generateLife"><i class="md md-fix">add</i></button>
            </div>
        </div>

        <div v-for="year in years" :key="year.number" class="year-container">
            <span class="row-label age-label">{{ year.age }}</span>
            <span class="row-label divider-label"> - </span>
            <span class="row-label year-label">{{ year.number }}</span>
            <div
                v-for="week in year.weeks"
                :key="week.number"
                :title="week.number"
                class="week-square"
                :class="[week.tags.filler ? 'filler' : '', week.tags.passed ? 'passed' : '']" />
        </div>
	</div>
</template>

<script>
export default {
    data () {
        return {
            initialWeek: 22,
            startYear: 1986,
            lifeOffset: 100,
            years: []
        }
    },
	mounted () {

    },
    methods: {
        generateLife () {
            this.years = []

            let now = new Date()
            let start = new Date(now.getFullYear(), 0, 0)
            let diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000)
            let oneDay = 1000 * 60 * 60 * 24
            let dayOfYear = Math.floor(diff / oneDay)

            // missing 35 week year handling
            const weekOfYear = Math.floor((10 + dayOfYear - (now.getDay() + 1)) / 7)
            console.log('week: ', weekOfYear)

            function yearFactor(year) {
                return (year + Math.floor(year / 4) - Math.floor(year / 100) + Math.floor(year / 400)) % 7
            }

            const localYears = []
            let age = 1

            for (let year = this.startYear; year < this.startYear + this.lifeOffset; year++) {
                const weeks = []
                let yearWeeks = 52

                if (yearFactor(year) == 4 || yearFactor(year - 1) == 3) {
                    yearWeeks += 1
                }

                for (let week = 1; week <= yearWeeks; week++) {
                    const tags = {}
                    if (year === this.startYear && week < this.initialWeek) {
                        tags.filler = true
                    } else if (year < now.getFullYear()) {
                        tags.passed = true
                    } else if (year == now.getFullYear() && week <= weekOfYear) {
                        tags.passed = true
                    }
                    weeks.push({ number: week, tags, data: [] })
                }

                localYears.push({ number: year, weeks, age: age++ })
            }
            this.years = localYears
        }
    }
}

</script>

!<style scoped>
    .year-container {
        display: flex;
        flex-direction: row;
        min-width: 1110px;
    }
    .row-label {
        font-size: x-small;
        line-height: 1;
    }
    .year-label {
        width: 30px;
        text-align: right;
    }
    .age-label {
        width: 15px;
    }
    .divider-label {
        width: 5px;
    }
    .week-square {
        margin: 1px 5px;
        height: 10px;
        width: 10px;
        border: 1px solid;
        background-color: chocolate;
    }
    .week-square.filler {
        background-color: gainsboro;
        border: 1px solid gray;
    }
    .week-square.passed {
        background-color: aquamarine;
    }
</style>
