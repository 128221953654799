<template>
	<div>
		<div class="modal-backdrop fade show"></div>
		<div class="fade modal show d-block" @click.self="$emit('hide-add-modal')" @keyup.esc="$emit('hide-add-modal')">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Add new Document
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('hide-add-modal')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-row mb-3">
							<label for="documentName">Name</label>
							<input type="text"
							id="documentName"
							ref="firstInput"
							v-model="document.name.value"
							class="form-control"
							:class="[document.name.error ? 'is-invalid' : '']"
							@input="document.name.value.length ? document.name.error = false : null">
						</div>

						<div class="form-row">
							<label for="documentUrl">URL</label>&nbsp;<span class="text-small text-muted">optional</span>
							<input type="text"
							id="documentUrl"
							v-model="document.url.value"
							class="form-control"
							:class="[document.url.error ? 'is-invalid' : '']"
							@input="document.url.value.length ? document.url.error = false : null">
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" @click="$emit('hide-add-modal')">Cancel</button>
						<button type="button" class="btn btn-primary" @click="submitChapter">Add</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		this.$refs.firstInput.focus();
	},
	data() {
		return {
			document: {
				name: {
					value: '',
					error: false,
				},
				url: {
					value: '',
					error: false,
				},
			},
		}
	},
	methods: {
		submitChapter() {
			function validate(input) {
				if (input.value.length <= 0) {
					input.error = true;
					return false;
				}
				input.error = false;
				return true;
			}
			if (validate(this.document.name)) {
				this.$store.dispatch('addDocument', this.document);
				this.$emit('hide-add-modal');
			}
		}
	},
}
</script>

<style scoped>
	.overlay.opaque {
		background-color: rgba(0, 0, 0, 0.5);
	}
</style>