<template>
	<div>
		<div class="modal-backdrop fade show"></div>
		<div class="fade modal show d-block" @click.self="$emit('hide-add-modal')" @keyup.esc="$emit('hide-add-modal')">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							{{ page ? 'Edit page' : 'Add new page to'}} <strong><slot></slot></strong>
						</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$emit('hide-add-modal')">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="form-row mb-3">
							<label for="pageName">Name</label>
							<input type="text"
							id="pageName"
							ref="firstInput"
							v-model="tempPage.name.value"
							class="form-control"
							:class="[tempPage.name.error ? 'is-invalid' : '']"
							@input="tempPage.name.value.length ? tempPage.name.error = false : null">
						</div>

						<div class="form-row mb-3">
							<label for="pageURL">URL</label>
							<div class="input-group">
								<div class="input-group-prepend">
									<div class="input-group-text">
										http://
									</div>
								</div>
								<input type="text"
								id="pageURL"
								v-model="tempPage.url.value"
								class="form-control"
								:class="[tempPage.url.error ? 'is-invalid' : '']"
								@input="tempPage.url.value.length ? tempPage.url.error = false : null; changed = true">
							</div>
						</div>

						<div class="form-row">
							<label for="pageSelector">Selector</label>
							<input type="text"
							id="pageSelector"
							v-model="tempPage.selector.value"
							class="form-control"
							:class="[tempPage.selector.error ? 'is-invalid' : '']"
							@input="tempPage.selector.value.length ? tempPage.selector.error = false : null; changed = true">
						</div>

					</div>
					<div class="modal-footer">
						<button v-if="page" type="button" class="btn btn-danger mr-auto" @click="deletePage">Delete</button>

						<button v-if="page" type="button"
						class="btn btn-success"
						:disabled="changed || refreshing"
						@click="refreshWordCount"
						:style="refreshing ? 'width: 66.53px' : ''">
							<span v-if="refreshing" class="spinner-border spinner-border-sm"></span>
							<span v-else>Refresh</span>
						</button>

						<button type="button" class="btn btn-secondary" @click="$emit('hide-add-modal')">Cancel</button>

						<button v-if="page" type="button" class="btn btn-primary" @click="submitPage">Save</button>
						<button v-else type="button" class="btn btn-primary" @click="submitPage">Add</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	mounted() {
		if (this.page && this.page.id) {
			this.tempPage.name.value = this.page.name;
			this.tempPage.url.value = this.page.url;
			this.tempPage.selector.value = this.page.selector;
		}
		this.$refs.firstInput.focus();
	},
	props: ['chapterId', 'page'],
	data() {
		return {
			changed: false,
			refreshing: false,
			tempPage: {
				name: {
					value: '',
					error: false,
				},
				url: {
					value: '',
					error: false,
				},
				selector: {
					value: '',
					error: false,
				},
			},
		}
	},
	methods: {
		deletePage() {
			this.$store.dispatch('deletePage', {page: this.page});
			this.$emit('hide-add-modal', 'deletePage');
		},
		refreshWordCount() {
			this.refreshing = true;
			this.$store.dispatch('refreshPage', this.page.id)
			.then(res => {
				this.refreshing = false;
			});
		},
		submitPage() {
			let valid = true;
			function validate(input) {
				if ( ! input.value.length > 0) {
					input.error = true;
					valid = false;
					return false;
				}
				return true;
			}

			validate(this.tempPage.name);
			validate(this.tempPage.url);
			validate(this.tempPage.selector);

			if (valid) {
				if (this.page) {
					this.$store.dispatch('editPage', {page: this.tempPage, pageId: this.page.id, chapterId: this.chapterId});
					this.$emit('hide-add-modal');
				} else {
					this.$store.dispatch('addPage', {page: this.tempPage, chapterId: this.chapterId});
					this.$emit('hide-add-modal', 'addPage');
				}
			}
		}
	},
}
</script>

<style scoped>
	.overlay.opaque {
		background-color: rgba(0, 0, 0, 0.5);
	}
</style>