<template>
	<li class="list-group-item pl-2 pr-1 py-2 h-1rem d-flex w-100">

		<transition :name=" ! editMode ? 'toggle-read' : ''" mode="out-in">
			<button
			type="button"
			class="btn btn-sm"
			:class="[page.read ? 'btn-success' : 'btn-danger']"
			@click="$store.dispatch('toggleCheck', page.id)"
			:disabled=" ! page.page_words"
			title="Toggle read"
			:key="page.read">
				<i class="md md-fix">{{ page.read ? 'check_box' : 'check_box_outline_blank' }}</i>
			</button>
		</transition>
		<div class="mr-auto pl-3 my-auto">
			<a :href="page.url" target="_blank">{{ page.name }}</a>
			<span v-if="page.page_words" class="badge badge-primary badge-pill ml-3">{{ page.page_words.toLocaleString() }}</span>
		</div>
		<button v-if=" ! page.page_words" type="button" class="btn btn-sm btn-success" title="Fetch page words" @click="refreshWordCount"><i class="md md-fix">refresh</i></button>
		<button type="button" class="btn btn-sm border-0 text-body py-0" @click="setEditMode()"><i class="md md-fix">more_horiz</i></button>

		<add-page v-if="addModalVisible" :chapterId="page.chapter_id" :page="page" @hide-add-modal="refreshHeight">{{page.name}}</add-page>
	</li>
</template>

<script>
import addPage from '../comp/addPage';

export default {
	components: {
		addPage
	},
	props: ['page'],
	data() {
		return {
			addModalVisible: false,
			editedPage: {
				name: null,
				url: null,
				selector: null,
			},
			editMode: false,
		}
	},
	methods: {
		refreshWordCount() {
			this.$store.dispatch('refreshPage', this.page.id);
		},
		refreshHeight(type) {
			this.addModalVisible = false;
			if (type == 'deletePage') {
				this.$emit('refresh-height', 'deletePage');
			}
		},
		setEditMode() {
			this.addModalVisible = true;
			this.editedPage = {
				name: this.page.name,
				url: this.page.url,
				selector: this.page.selector,
			}
			this.editMode = true;
		},
	},
}
</script>

<style scoped>
	.h-1rem {
		height: 3rem;
	}
	.btn-custom:focus  {
		outline: thin dotted;
		box-shadow: none;
	}

	.toggle-read-enter-active {
		opacity: 0.5;
	}

	.toggle-read-leave-active {
				opacity: 0.5;
	}
</style>