<template>
	<div class="position-fixed center-messages">
		<transition-group name="alerts" tag="div">
			<div v-for="alert in alerts" class="alert show alert-dismissible center-message" :class="'alert-' + alert.type" :key="alert.id">
				<span v-html="alert.message"></span>
				<button type="button" class="close" aria-label="Close" @click="dismissAlert(alert.id)">&times;</button>
			</div>
		</transition-group>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	methods: {
		dismissAlert(id) {
			this.$store.commit('removeAlert', {id});
		},
	},
	computed: {
		...mapState(['alerts']),
	}
}
</script>

<style scoped>
	.center-messages {
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 2000;
	}

	.center-message {
		margin-left: auto;
		margin-right: auto;
		width: max-content;
	}

	.alert {
		transition: all 0.5s;
	}

	.alerts-enter,
	.alerts-leave-to {
		opacity: 0;
		transform: scale3d(0.8, 0.8, 1);
	}

	.alerts-leave-active {
		z-index: -1;
	}
</style>