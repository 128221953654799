<template>
	<div class="container d-flex justify-content-center align-items-center login">
		<div class="card">
			<div class="card-header">
				<div class="text-center"><strong>Log In</strong></div>
			</div>
			<form @submit.prevent="signIn" class="card-body">
				<div class="form-group">
					<label for="email">Email</label>
					<input type="email" name="email" id="email" class="form-control" :class="[logInError ? 'is-invalid' : '']">
				</div>
				<div class="form-group">
					<label for="password">Password</label>
					<input type="password" name="password" id="password" class="form-control" :class="[logInError ? 'is-invalid' : '']">
				</div>
				<div class="d-flex justify-content-center">
					<button class="btn btn-primary">Sign In</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

export default {
	data() {
		return {
			logInError: false,
		}
	},
	methods: {
		signIn: function (e) {
			let data = new FormData(e.target);
			axios.post('/api/login', data)
			.then(res => {
				if (res.data.token && res.data.user) {
					this.$store.commit('setUser', {'user': res.data.user, 'token': res.data.token});
					this.$router.push({name: 'chapters'});
					this.$store.commit('addAlert', {message: `Signed in as <strong>${res.data.user.name}</strong>`, type: 'success'});

				} else {
					console.error(res.data.message);
					this.logInError = true;
					this.$store.commit('unsetUser');
					this.$store.commit('addAlert', {message: `Bad credentials`, type: 'danger'});
				}
			})
			.catch(err => {
				this.$store.commit('unsetUser');
				this.$store.commit('addAlert', {message: `Error signing in`, type: 'danger'});
			});
		},
	},
}
</script>

<style scoped>
	.alert {
		position: fixed;
		left: 1rem;
		bottom: 0;
	}
	.login {
		height: 100vh;
	}

</style>